import { activityStatuses } from "features/activity/activityConsts";
import { sum } from "lodash";
import FormatService from "services/formatService";
import GraphHeader from "../GraphHeader/GraphHeader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";

const WorkorderCostTotal = ({ workorders }) => {
    const classes = workorderDashboardPageStyles();

    const completed = workorders?.filter(a => a.activityStatus == activityStatuses.Completed) ?? [];
    const total = sum(completed?.map(a => a.revenue) ?? []);

    return (
        <div style={{ height: 350 }}>
            <GraphHeader title={'Total Cost'} />
            <div className={classes.totalCost}>
                {FormatService.formatCurrency(total)}
            </div>
        </div>
    )
}

export default WorkorderCostTotal;
