
export const TextElement = ({ value, parse = true, style = {} }) => {

    // let valueStr = value + "";
    // try {
    //     valueStr = (value && parse ? JSON.parse(value ?? "") : "")?.toString()
    // } catch (error) {
    //     console.log(`TextElement Failed to format value ${value + ""}`)
    // }

    return (
        <div style={{ ...style }} >{value + ""}</div>
    )
}