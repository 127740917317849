import { Chip, Grid, Tooltip } from "@material-ui/core";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery } from "features/activity/activitySlice";
import { groupBy } from "lodash";
import GraphHeader from "../GraphHeader/GraphHeader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";
import IconMechanicalWrapper from "icons/mechanicals/IconMechanicalWrapper";
import { useState } from "react";
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const cleaning = 'Cleaning';
const mechanicals = 'Mechanicals';
const inspection = 'Inspection';

const WorkorderTypesCard = ({ workorders, showAverage = true, loading }) => {
    const classes = workorderDashboardPageStyles();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    const cleaningType = activityTypes?.find(a => a.name == cleaning);
    const mechanicalsType = activityTypes?.find(a => a.name == mechanicals);
    const inspectionType = activityTypes?.find(a => a.name == inspection);

    const [selectedType, setSelectedType] = useState(mechanicals);

    const averages = [];
    workorders = selectedType == 'All' ? workorders : workorders?.filter(a => {
        if (selectedType == cleaning && a.activityTypeId == cleaningType?.id) return true;
        if (selectedType == mechanicals && a.activityTypeId == mechanicalsType?.id) return true;
        if (selectedType == inspection && a.activityTypeId == inspectionType?.id) return true;
        return false;
    });
    const groupedByActivityType = groupBy(workorders, 'activityItemId');

    let highestNumbeOfActivities = 0;
    Object.keys(groupedByActivityType).map(key => {
        const activitiesForType = groupedByActivityType[key];
        const count = activitiesForType?.length ?? 0;
        const avg = showAverage ? Math.ceil(count > 0 ? count / 12 : 0) : count;
        if (avg > highestNumbeOfActivities) {
            highestNumbeOfActivities = avg;
        }
        const item = activityItems?.find(a => a.id == key);
        averages.push({
            activityItemId: key,
            name: item?.name ?? 'Unknown',
            icon: item?.icon,
            avg: avg,
            percentage: avg > 0 ? avg / highestNumbeOfActivities * 100 : 0,
        });
    });


    return (
        <div>
            <Grid container alignItems="center" justifyContent="space-between">
                <GraphHeader 
                    title={'Activity Types'} 
                    rightContent={<TypeViewSelector selectedType={selectedType} setSelectedType={setSelectedType} />}
                />
            </Grid>
            {loading ? <Grid container spacing={5}>
                <Grid item xs={6}>
                    <ShimmerLoader count={5} height="30" />
                </Grid>
                <Grid item xs={6}>
                    <ShimmerLoader count={5} height="30" />
                </Grid>
            </Grid> :
            <Grid container spacing={1} >
                {averages?.map(item => <Grid item container xs={6} spacing={1} alignItems="center">
                    <Grid item >
                        <IconMechanicalWrapper activityItem={item} className={classes.typeIcon} />
                    </Grid>
                    <Grid item xs={4} ><b>{item?.name}</b></Grid>
                    <Grid item xs={6}>
                        <div className={classes.activityCountWrapper}>
                            <Tooltip title={item.avg}>
                                <div className={classes.activityCount} style={{ width: `${item.avg > 0 ? item.avg / highestNumbeOfActivities * 100 : 0}%` }}>
                                    {item.avg}
                                </div>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>)}
            </Grid>}
        </div>
    )
}

export default WorkorderTypesCard;


const TypeViewSelector = ({ selectedType, setSelectedType }) => {
    const chip = (type) => {
        return <Chip variant="outlined" onClick={() => setSelectedType(type)} label={type} color={selectedType == type ? "primary" : ""} />
    }
    return (
        <Grid container alignContent='center' spacing={1}>
            <Grid item>
                {chip(mechanicals)}
            </Grid>
            <Grid item>
                {chip(cleaning)}
            </Grid>
            <Grid item>
                {chip(inspection)}
            </Grid>
            <Grid item>
                {chip('All')}
            </Grid>
            <Grid item>
                <Tooltip title={'This table shows a complete list of all activity types. The numbers are the average number of activities completed per month of each type. This graphic responds to the filters in its corner, as well as the filters on top of this dash'}>
                    <InfoOutlinedIcon />
                </Tooltip>
            </Grid>
        </Grid>
    )
}
