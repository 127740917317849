import { questionTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { DateElement } from "./DateElement";
import { ImageElement } from "./ImageElement";
import { SignatureElement } from "./SignatureElement";
import { TextElement } from "./TextElement";
import { ValueElement } from "./ValueElement";
import { FormOutputStyle } from "../style";
import { Grid } from "@material-ui/core";
import { SingleValueElement } from "./SingleValueElement";
import { BooleanElement } from "./BooleanElement";

export const QuestionElement = ({ element, question, answer, base64ImagesLookup }) => {
    const classes = FormOutputStyle();

    const questionTypeComponents = {
        [questionTypes.Date]: DateElement,
        [questionTypes.Freetext]: TextElement,
        [questionTypes.Dropdown]: SingleValueElement,
        [questionTypes.Image]: ImageElement,
        [questionTypes.MultipleChoice]: ValueElement,
        [questionTypes.Signature]: SignatureElement,
        //[questionTypes.Boolean]: BooleanElement,
    };

    const QuestionComponent = questionTypeComponents[question?.questionType];

    const getValue = () => {
        switch (question?.questionType) {
            case questionTypes.Image:
            case questionTypes.Signature:
                return `data:image/png;base64,${base64ImagesLookup?.[answer?.id]?.imageData}`;
            default:
                return answer?.values
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', pageBreakInside: 'avoid' }} >
            <div style={{ width: '65%' }} >
                {element.showQuestion && <div style={{ fontSize: '1.2em', color: '#363D47' }} className={classes.questionText}>{question?.question}</div>}
                <div style={{ fontSize: '1em', color: '#363D47', fontWeight: 'bold' }}>
                    {QuestionComponent ? <QuestionComponent value={getValue()} /> : <></>}
                </div>
                {!!answer?.note && <div>{answer?.note}</div>}
            </div >
            {question?.questionType != questionTypes.Signature && question?.questionType != questionTypes.Image && !!answer?.pictureUrl && !!base64ImagesLookup[answer.id]?.imageData &&
                <div style={{ width: '30%', marginLeft: '5%' }} ><ImageElement url={answer?.pictureUrl} value={`data:image/png;base64,${base64ImagesLookup?.[answer?.id]?.imageData}`} /></div>}
        </div>
    )
}