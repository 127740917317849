import { Grid, Tooltip } from "@material-ui/core";
import ExportButton from "components/ExportButton/ExportButton";
import { activityStatusById } from "features/activity/activityConsts";
import { IconDownload } from "icons";
import { useSelector } from "react-redux";
import ActivityListDialog from "../../MechanicalDashboard/ActivityList/ActivityListDialog";
import { GraphLoader } from "../GraphLoader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const WorkorderByStatusCountCard = ({ loading, workorders, statuses = [], text, mapExportData, filterDataByField, additionalFilters, tooltipText }) => {

    const classes = workorderDashboardPageStyles();
    const { filters } = useSelector(state => state.workorderDashboardState);

    const filteredWorkorders = workorders?.filter(a => statuses.includes(a.activityStatus) || (filterDataByField && !!a?.[filterDataByField]?.id));

    return (
        <>
            <Grid className={classes.cardWrapper} container direction="column">
                <Grid container justifyContent='flex-end' alignItems='center' className={classes.actions}>
                    <ActivityListDialog
                        additionalFilters={additionalFilters}
                        status={statuses}
                        clientId={filters?.clientId}
                        lotId={filters?.lotId}
                    />
                    <ExportButton className={classes.exportButton} btnStyle='text' label="" icon={<IconDownload/>}
                                    fileLabel="Export" data={filteredWorkorders} mapExportData={mapExportData}/>
                    <Tooltip title={tooltipText}>
                        <InfoOutlinedIcon />
                    </Tooltip>
                </Grid>
                <Grid item className={classes.count} style={{fontWeight: 400}}>{filteredWorkorders?.length ?? 0}</Grid>
                <Grid item>
                    <div className={classes.text}
                        style={{fontWeight: 400}}>{text ?? activityStatusById[statuses?.[0]]?.name}</div>
                </Grid>
                <GraphLoader loading={loading} position="bottom"/>
            </Grid>
        </>
    )
}

export default WorkorderByStatusCountCard;
