import { Checkbox, FormControlLabel, Grid, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from "@mui/material";
import { setLoadsErrors } from "features/loads/loadsStateSlice";
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../../../components/CustomInput';
import DatePicker from '../../../../../components/DatePicker';
import Validator from '../../../../../services/validator';
import { useGetClientsWithConfigsQuery } from '../../../../clients/clientConfigSlice';
import { useGetLotsQuery } from '../../../../lots/lotSlice';
import { selectIsClient, selectUserClientId, userHasOneClient } from '../../../../user/userSlice';
import { calcTypeMessages, calcTypesById, groupLegTypes, trailerTypes } from '../../../enums';
import LocationDistance from '../../CreateLoadForm/LocationDistance';
import LoadFormStyles from "./LoadFormStyles";
import LocationField from "./LocationField";

const useStyles = LoadFormStyles;

const GroupLoadForm = ({ loadGroup, setLoadGroup, clientConfigs, setClientConfigs, legIndex = 0 }) => {

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);

    clientsWithConfigs = clientsWithConfigs || [];

    const isClient = useSelector(selectIsClient);
    const loggedInUserClientId = useSelector(selectUserClientId);
    const { showMandatoryError, loadsErrors } = useSelector(state => state.loadState);

    const dispatch = useDispatch();

    const classes = useStyles();
    const defaultDateMessage = "Please select move type";
    const [warnings, setWarnings] = useState({})
    const [pickupMinDate, setPickupMinDate] = useState(moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment());
    const [deliveryMinDate, setDeliveryMinDate] = useState(moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment());
    const [deliveryMaxDate, setDeliveryMaxDate] = useState();
    const [pickupDateDisabled, setPickupDateDisabled] = useState(true);
    const [deliveryDateDisabled, setDeliveryDateDisabled] = useState(true);
    const [puDateMessage, setPuDateMessage] = useState(defaultDateMessage);
    const [dDateMessage, setDDateMessage] = useState(defaultDateMessage);
    const [clientMoveTypes, setClientMoveTypes] = useState();
    const [loadMoveType, setLoadMoveType] = useState();
    const [showWarning, setShowWarning] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const hasOneClaim = useSelector(userHasOneClient);

    const isLoading = () => isLoadingClientsWithConfigs || isLoadingLots;

    const destinationFormulaCalc = () => Math.ceil(loadGroup?.loads?.[legIndex]?.miles / 550);

    const onChangeValue = (values) => {
        let loads = [...loadGroup?.loads?.map(l => ({ ...l }))];
        if (loads) {
            loads[legIndex] = { ...loads?.[legIndex], ...values }
            setLoadGroup({ ...loadGroup, loads });
        }
    }

    const getWarningMessageForField = {
        'pickupStartDate': (val) => {
            const daysToPush = daysToAddByOperation(loadGroup?.loads?.[legIndex]?.pickupLocationId, moment(val, "YYYY-MM-DD"), true)
            if (daysToPush != 0) {
                setShowWarning(true);
                setStartTimer(true);
                return `The selected pickup lot is not open on the selected date, the date has been postponed`;
            }
            return false;
        },
        'deliveryStartDate': (val) => {
            const daysToPush = daysToAddByOperation(loadGroup?.loads?.[legIndex]?.deliveryLocationId, moment(val, "YYYY-MM-DD"), true)
            if (daysToPush != 0) {
                setShowWarning(true);
                setStartTimer(true);
                return `The selected delivery lot is not open on the selected date, the date has been brought forward`;
            }
            return false;
        }
    }

    const getErrorMessagesForField = {
        'deliveryPocEmail': (val) => {
            if (val && loadGroup?.loads?.[legIndex]?.destinationNewLot && !Validator.validateEmail(val)) {
                return "Invalid delivery POC email";
            }
            return false;
        },
        'pickupPocEmail': (val) => {
            if (val && loadGroup?.loads?.[legIndex]?.originNewLot && !Validator.validateEmail(val)) {
                return "Invalid pickup POC email";
            }
            return false;
        },
        'deliveryPhone': (val) => {
            if (val && loadGroup?.loads?.[legIndex]?.destinationNewLot && !Validator.validatePhone(val)) {
                return "Invalid delivery phone";
            }
            return false;
        },
        'pickupPhone': (val) => {
            if (val && loadGroup?.loads?.[legIndex]?.originNewLot && !Validator.validatePhone(val)) {
                return "Invalid pickup phone";
            }
            return false;
        },
        'pickupStartDate': (val) => {
            if (val && ((moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment()).add(loadMoveType?.minPickup, "days").format("YYYY-MM-DD") > val)) {
                onChangeValue({ "deliveryStartDate": null })
                return "Based on the selected move type, the pickup date calculated by the transit time does not meet the requirement of the minimum pickup date. Please choose a later delivery date";
            }
            if (loadMoveType?.maxDelivery > 0 && loadMoveType?.maxDelivery < destinationFormulaCalc()) {
                onChangeValue({ "pickupLocationId": null, "deliveryLocationId": null })
                return "Based on the selected move type and lots, transit time does not meet the requirement of the maximum delivery date.";
            }
            if (moment(val, "YYYY-MM-DD") < (moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment()).startOf('day')) {
                return "Pickup date can not be in the past";
            }
            if (loadMoveType?.maxDelivery > 0 && moment(val).add(loadMoveType?.maxDelivery, "days").format("YYYY-MM-DD") < loadGroup?.loads?.[legIndex]?.deliveryStartDate && loadGroup?.loads?.[legIndex]?.deliveryStartDate) {
                return "Based on the selected move type and lots, pickup date calculated does not meet the requirement of the maximum delivery date.";
            }
            return false;
        },
        'deliveryStartDate': (val) => {
            if (loadMoveType?.maxDelivery > 0 && moment(val).subtract(loadMoveType?.maxDelivery, "days").format("YYYY-MM-DD") > loadGroup?.loads?.[legIndex]?.pickupStartDate && loadGroup?.loads?.[legIndex]?.pickupStartDate) {
                return "Based on the selected move type and lots, delivery date calculated does not meet the requirement of the maximum delivery date.";
            }
        }
    }

    const clearErrorForFields = (keys) => {
        let newErrors = loadsErrors?.[loadGroup?.loads?.[legIndex]?.uuid] || {};
        keys.map(key => {
            if (newErrors[key]) newErrors = { ...newErrors, [key]: null };
        })
        dispatch(setLoadsErrors({ ...loadsErrors, [loadGroup?.loads?.[legIndex]?.uuid]: newErrors }));
    };

    const onChange = (key, val) => {
        const warningMessage = getWarningMessageForField[key] && getWarningMessageForField[key](val)
        if (warningMessage) {
            setWarnings({ ...warnings, [key]: warningMessage });
        }
        const errorMessage = getErrorMessagesForField[key] && getErrorMessagesForField[key](val)
        if (errorMessage) {
            let errors = loadsErrors?.[loadGroup?.loads?.[legIndex]?.uuid] || {};
            errors = { ...errors, [key]: errorMessage }
            dispatch(setLoadsErrors({ ...loadsErrors, [loadGroup?.loads?.[legIndex]?.uuid]: errors }))
        }
        else {
            clearErrorForFields([key]);
        }
        onChangeValue({ [key]: val });
    };

    const onChangeLocation = () => {
        onMoveTypeChange(loadGroup?.loads?.[legIndex]?.configMoveType);
    };

    const onChangePickupDate = (val) => {
        if (loadGroup?.loads?.[legIndex]?.deliveryStartDate) {
            onChange("pickupStartDate", val)
            onChangeValue({ "deliveryStartDate": null, "pickupStartDate": val })
            clearErrorForFields(["pickupStartDate"]);
        }
        else {
            onChange("pickupStartDate", val)
        }
    };

    const setMoveTypeConfigs = () => {
        let clientConfigs = [];
        const clientId = (isClient && hasOneClaim) ? loggedInUserClientId : loadGroup?.clientId;
        clientConfigs = clientsWithConfigs?.find(c => c.id == clientId);

        if (!clientConfigs && clientsWithConfigs.length === 1) {
            clientConfigs = clientsWithConfigs[0];
        }

        setClientConfigs && setClientConfigs(clientConfigs);
        setClientMoveTypes(clientConfigs?.loadMoveTypes);
        setLoadMoveType(clientConfigs?.loadMoveTypes?.find(m => m.id == loadGroup?.loads?.[legIndex]?.configMoveType));
    };

    const daysToAddByOperation = (lotId, date, goBack = false) => {
        date = moment(date);
        if (date || lotId) {
            const lot = lots?.find(l => l.id == lotId);
            const groupedSchedule = _.groupBy(lot?.schedule, 'dayOfWeekId');

            if (goBack) {
                for (let i = 0; i < 6; i++) {
                    let localDay = date?.day() + 1 - i;
                    localDay = localDay > 7 ? ~~(localDay / 7) : localDay;
                    if (groupedSchedule?.[localDay])
                        return -i;
                }
            }
            else {
                for (let i = 0; i < 6; i++) {
                    let localDay = date?.day() + 1 + i;
                    localDay = localDay > 7 ? ~~(localDay / 7) : localDay;
                    if (groupedSchedule?.[localDay])
                        return i;
                }
            }
        }
        return 0; //open on selected day or 24/7, return selected day
    }


    const setPickupMinDateForAllSchedulingLogics = (configs) => {
        let puMinDate = moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment();
        puMinDate.add(configs?.minPickup, 'days');
        puMinDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.pickupLocationId, puMinDate), 'days');
        puMinDate = moment(puMinDate).format("YYYY-MM-DD");
        setPickupMinDate(puMinDate);
    }

    const setDeliveryMinDateBySchedulingLogic = {
        [calcTypesById.NO_LOGIC]: (pickupStartDate) => {
            let dMinDate = moment(pickupStartDate);
            dMinDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.deliveryLocationId, dMinDate), 'days');
            dMinDate = moment(dMinDate).format("YYYY-MM-DD");
            setDeliveryMinDate(dMinDate);
        },
        [calcTypesById.PICKUP_FROM_DELIVERY]: (configs) => {
            let dMinDate = moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment();
            dMinDate.add(configs?.minPickup, 'days');
            dMinDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.pickupLocationId, dMinDate), 'days');
            dMinDate.add(destinationFormulaCalc(), 'days');
            dMinDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.deliveryLocationId, dMinDate), 'days');
            dMinDate = moment(dMinDate).format("YYYY-MM-DD");
            setDeliveryMinDate(dMinDate);
            const today = moment(loadGroup?.loads?.[legIndex]?.createdDate) || moment().format("YYYY-MM-DD");
            const diff = moment(dMinDate).diff(today.format("YYYY-MM-DD"), 'days');
            setDDateMessage(calcTypeMessages[calcTypesById.PICKUP_FROM_DELIVERY].delivery(diff, configs?.maxDelivery));
        },
        [calcTypesById.MIN_TRANSIT_TIME]: (pickupStartDate) => {
            let dMinDate = moment(pickupStartDate, "YYYY-MM-DD");
            dMinDate.add(destinationFormulaCalc(), 'days');
            dMinDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex].deliveryLocationId, dMinDate), 'days');
            dMinDate = moment(dMinDate).format("YYYY-MM-DD");
            setDeliveryMinDate(dMinDate);
            const pickup = moment(pickupStartDate).format("YYYY-MM-DD");
            const diff = moment(dMinDate).diff(pickup, 'days');
            setDDateMessage(calcTypeMessages[calcTypesById.MIN_TRANSIT_TIME].delivery(diff, loadMoveType?.maxDelivery));
            return dMinDate;
        }
    }

    const setDeliveryMaxDateForAllSchedulingLogics = (pickupStartDate) => {
        if (loadMoveType?.maxDelivery > 0) {
            let dMaxDate = moment(pickupStartDate, "YYYY-MM-DD");
            dMaxDate.add(loadMoveType?.maxDelivery, 'days');
            dMaxDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.deliveryLocationId, dMaxDate, true), 'days');
            dMaxDate = moment(dMaxDate).format("YYYY-MM-DD");
            setDeliveryMaxDate(dMaxDate);
            return dMaxDate;
        }
        else {
            setDeliveryMaxDate(null);
            return null;
        }
    }

    const determineSchedulingLogic = {
        [calcTypesById.NO_LOGIC]: (configs) => {
            setPickupDateDisabled(false);
            setDeliveryDateDisabled(true);
            setPickupMinDateForAllSchedulingLogics(configs);
            setPuDateMessage(calcTypeMessages[calcTypesById.NO_LOGIC].pickup(configs.minPickup));
            onChangeValue({ "configMoveType": configs.id, "pickupStartDate": null, "deliveryStartDate": null })
            setDDateMessage("Please select pickup date");
        },
        [calcTypesById.PICKUP_FROM_DELIVERY]: (configs) => {
            setPickupDateDisabled(true);
            setDeliveryDateDisabled(true);
            onChangeValue({ "configMoveType": configs.id, "pickupStartDate": null, "deliveryStartDate": null })
            setDDateMessage("Please select locations");
            setPuDateMessage("Please select locations");
        },
        [calcTypesById.DELIVERY_FROM_PICKUP]: (configs) => {
            setPickupDateDisabled(true);
            setDeliveryDateDisabled(true);
            setPickupMinDateForAllSchedulingLogics(configs);
            onChangeValue({ "configMoveType": configs.id, "pickupStartDate": null, "deliveryStartDate": null })
            setPuDateMessage("Please select locations");
            setDDateMessage("Please select pickup date");
        },
        [calcTypesById.MIN_TRANSIT_TIME]: (configs) => {
            setPickupDateDisabled(false);
            setDeliveryDateDisabled(true);
            setPickupMinDateForAllSchedulingLogics(configs);
            onChangeValue({ "configMoveType": configs.id, "pickupStartDate": null, "deliveryStartDate": null })
            setDDateMessage("Please select pickup date");
            setPuDateMessage(calcTypeMessages[calcTypesById.MIN_TRANSIT_TIME].pickup(configs.minPickup));
        }
    }

    const onMoveTypeChange = (val) => {
        setDeliveryMinDate(null);
        setDeliveryMaxDate(null);
        setMoveTypeConfigs();
        const configs = clientMoveTypes?.find(m => m.id == val);
        if (configs) {
            if (determineSchedulingLogic[configs.calcType]) {
                clearErrorForFields(['pickupStartDate', 'deliveryStartDate']);
                determineSchedulingLogic[configs.calcType](configs);
            }
        }
    }

    useEffect(() => {
        if (startTimer) {
            setTimeout(() => {
                setShowWarning(false);
                setStartTimer(false);
                setWarnings({});
            }, 4000);
        }
    }, [startTimer]);

    useEffect(() => {
        setMoveTypeConfigs();
    }, [loadGroup?.clientId, loadGroup?.loads?.[legIndex]?.configMoveType, clientsWithConfigs])


    const getLegalPickupStartDate = () => {
        let pickupStartDate = moment(loadGroup?.loads?.[legIndex]?.pickupStartDate);
        //check pickup opening hours
        if (loadGroup?.loads?.[legIndex]?.pickupLocationId && pickupStartDate._isValid) {
            let daysToPush = daysToAddByOperation(loadGroup?.loads?.[legIndex]?.pickupLocationId, pickupStartDate, true)
            if (daysToPush != 0) {
                pickupStartDate.add(daysToPush, 'days');
                pickupStartDate = moment(pickupStartDate).format("YYYY-MM-DD");
                onChange('pickupStartDate', pickupStartDate);
            }
        }
        return moment(pickupStartDate);
    }

    const getLegalDeliveryStartDate = () => {
        let deliveryStartDate = moment(loadGroup?.loads?.[legIndex]?.deliveryStartDate);

        //check delivery opening hours
        if (loadGroup?.loads?.[legIndex]?.deliveryLocationId && deliveryStartDate._isValid) {
            const daysToPush = daysToAddByOperation(loadGroup?.loads?.[legIndex]?.deliveryLocationId, deliveryStartDate);
            if (daysToPush != 0) {
                deliveryStartDate.add(daysToPush, 'days');
                deliveryStartDate = moment(deliveryStartDate).format("YYYY-MM-DD");
                onChange('deliveryStartDate', deliveryStartDate);
            }
        }
        return moment(deliveryStartDate);
    }

    const getPickupMinDate = () => {
        for (let i = legIndex - 1; i >= 0; i--) {
            let leg = loadGroup?.loads?.[i];
            if (leg?.type === groupLegTypes.ACTIVITY) {
                if (leg?.activityDate) {
                    return new Date(Math.max.apply(null, [new Date(pickupMinDate), new Date(leg?.activityDate)]))
                }
            } else {
                if (leg?.deliveryStartDate) {
                    return new Date(Math.max.apply(null, [new Date(pickupMinDate), new Date(leg?.deliveryStartDate)]))
                } else if (leg?.pickupStartDate) {
                    return new Date(Math.max.apply(null, [new Date(pickupMinDate), new Date(leg?.pickupStartDate)]))
                }
            }
        }
        return pickupMinDate;
    }

    const getPickupMaxDate = () => {
        for (let i = legIndex + 1; i < loadGroup?.loads?.length; i++) {
            let leg = loadGroup?.loads?.[i];
            if (leg?.type === groupLegTypes.TRANSPORT) {
                if (leg?.pickupStartDate) {
                    return moment(new Date(leg?.pickupStartDate)).format("YYYY-MM-DD")
                } else if (leg?.deliveryStartDate) {
                    return moment(new Date(leg?.deliveryStartDate)).format("YYYY-MM-DD")
                }
            } else {
                if (leg?.activityDate) {
                    return moment(new Date(leg?.activityDate)).format("YYYY-MM-DD")
                }
            }
        }
        return null;
    }

    const getDeliveryMaxDate = () => {
        for (let i = legIndex + 1; i < loadGroup?.loads?.length; i++) {
            let leg = loadGroup?.loads?.[i];
            if (leg?.type === groupLegTypes.TRANSPORT) {
                if (leg?.pickupStartDate) {
                    if (deliveryMaxDate) {
                        return moment(new Date(Math.min.apply(null, [new Date(deliveryMaxDate), new Date(leg?.pickupStartDate)])));
                    } else {
                        return moment(new Date(loadGroup?.loads?.[i]?.pickupStartDate)).format("YYYY-MM-DD")
                    }
                } else if (leg?.deliveryStartDate) {
                    if (deliveryMaxDate) {
                        return moment(new Date(Math.min.apply(null, [new Date(deliveryMaxDate), new Date(leg?.deliveryStartDate)])));
                    } else {
                        return moment(new Date(loadGroup?.loads?.[i]?.deliveryStartDate)).format("YYYY-MM-DD")
                    }
                }
            } else {
                if (leg?.activityDate) {
                    if (deliveryMaxDate) {
                        return moment(new Date(Math.min.apply(null, [new Date(deliveryMaxDate), new Date(leg?.activityDate)])));
                    } else {
                        return moment(new Date(loadGroup?.loads?.[i]?.activityDate)).format("YYYY-MM-DD")
                    }
                }
            }
        }
        return deliveryMaxDate ? moment(deliveryMaxDate).format("YYYY-MM-DD") : null;
    }

    const getDeliveryMinDate = () => {
        for (let i = legIndex - 1; i >= 0; i--) {
            let leg = loadGroup?.loads?.[i];
            if (leg?.type === groupLegTypes.TRANSPORT) {
                if (leg?.deliveryStartDate) {
                    return new Date(Math.max.apply(null, [new Date(deliveryMinDate), new Date(leg?.deliveryStartDate)]))
                } else if (leg?.pickupStartDate) {
                    return new Date(Math.max.apply(null, [new Date(deliveryMinDate), new Date(leg?.pickupStartDate)]))
                }
            } else {
                if (leg?.activityDate) {
                    return new Date(Math.max.apply(null, [new Date(deliveryMinDate), new Date(leg?.activityDate)]))
                }
            }
        }
        return deliveryMinDate;
    }

    useEffect(() => {
        getLegalPickupStartDate();
        getLegalDeliveryStartDate();
        if (loadMoveType?.calcType == calcTypesById.NO_LOGIC) {
            setPickupMinDateForAllSchedulingLogics(loadMoveType);
            setPuDateMessage(calcTypeMessages[calcTypesById.NO_LOGIC].pickup(loadMoveType?.minPickup));
            setPickupDateDisabled(false);
            //we need pickup date for claculating min and max delivery dates
            if (getLegalPickupStartDate()._isValid) {
                setDeliveryDateDisabled(false);
                setDeliveryMinDateBySchedulingLogic[calcTypesById.NO_LOGIC](getLegalPickupStartDate());
                setDeliveryMaxDateForAllSchedulingLogics(getLegalPickupStartDate());
                setDDateMessage(calcTypeMessages[calcTypesById.NO_LOGIC].delivery(loadMoveType?.maxDelivery));
            }
        }

        else if (loadMoveType?.calcType == calcTypesById.PICKUP_FROM_DELIVERY) {
            setDeliveryMinDateBySchedulingLogic[calcTypesById.PICKUP_FROM_DELIVERY](loadMoveType);
            setDeliveryDateDisabled(false);
            //we need miles for the dates caculation
            if (loadGroup?.loads?.[legIndex]?.miles >= 0) {
                //once we have delivery date we could calculate pickup date, because it's based on the transit time
                if (getLegalDeliveryStartDate()._isValid) {
                    let pickupStartDate = moment(getLegalDeliveryStartDate());
                    pickupStartDate.subtract(destinationFormulaCalc(), 'days');
                    pickupStartDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.pickupLocationId, pickupStartDate, true), 'days');
                    pickupStartDate = moment(pickupStartDate).format("YYYY-MM-DD");
                    onChange('pickupStartDate', pickupStartDate);
                    const diff = moment(getLegalDeliveryStartDate()).diff(pickupStartDate, 'days');
                    setPuDateMessage(calcTypeMessages[calcTypesById.PICKUP_FROM_DELIVERY].pickup(diff));
                }
                else {
                    setPuDateMessage("Please select delivery date");
                }
            }
        }

        else if (loadMoveType?.calcType == calcTypesById.DELIVERY_FROM_PICKUP) {
            setPickupMinDateForAllSchedulingLogics(loadMoveType);
            setPuDateMessage(calcTypeMessages[calcTypesById.DELIVERY_FROM_PICKUP].pickup(loadMoveType?.minPickup));
            setPickupDateDisabled(false);
            //we need miles for the dates caculation
            if (loadGroup?.loads?.[legIndex]?.miles) {
                //once we have pickup date we could calculate delivery date, because it's based on the transit time
                if (getLegalPickupStartDate()._isValid) {
                    let deliveryDate = moment(getLegalPickupStartDate());
                    deliveryDate.add(destinationFormulaCalc(), 'days');
                    deliveryDate.add(daysToAddByOperation(loadGroup?.loads?.[legIndex]?.deliveryLocationId, deliveryDate), 'days');
                    deliveryDate = moment(deliveryDate).format("YYYY-MM-DD");
                    onChange('deliveryStartDate', deliveryDate);
                    const diff = moment(deliveryDate).diff(getLegalPickupStartDate().format("YYYY-MM-DD"), 'days');
                    setDDateMessage(calcTypeMessages[calcTypesById.DELIVERY_FROM_PICKUP].delivery(diff));
                }
            }
            else {
                setDDateMessage("Please select locations");
            }
        }

        else if (loadMoveType?.calcType == calcTypesById.MIN_TRANSIT_TIME) {
            setPickupDateDisabled(false);
            setPickupMinDateForAllSchedulingLogics(loadMoveType);
            setPuDateMessage(calcTypeMessages[calcTypesById.MIN_TRANSIT_TIME].pickup(loadMoveType?.minPickup));
            //we force the user to select pickup date first, so we could calculate delivery min and max dates
            if (getLegalPickupStartDate()._isValid) {
                //we need miles, cause calculation is based on the transit time
                if (loadGroup?.loads?.[legIndex]?.miles >= 0) {
                    let dMinDate = setDeliveryMinDateBySchedulingLogic[calcTypesById.MIN_TRANSIT_TIME](getLegalPickupStartDate());
                    let dMaxDate = setDeliveryMaxDateForAllSchedulingLogics(getLegalPickupStartDate());

                    //conflict - minDelivery = (pickup + transit + weekend) > maxDelivery = (pickup + loadMoveType.maxDelivery)
                    if (dMinDate > dMaxDate) {
                        let errors = loadsErrors?.[loadGroup?.loads?.[legIndex]?.uuid] || {};
                        errors = { ...errors, [`pickupStartDate`]: "Based on the selected move type, the delivery date calculated by the transit time is later than the latest delivery allowed" }
                        dispatch(setLoadsErrors({ ...loadsErrors, [loadGroup?.loads?.[legIndex]?.uuid]: errors }))
                    }
                    setDeliveryDateDisabled(false);
                }
                else {
                    setDDateMessage("Please select locations");
                }
            }
        }

    }, [loadGroup?.loads?.[legIndex]?.miles, loadGroup?.loads?.[legIndex]?.pickupStartDate, loadGroup?.loads?.[legIndex]?.deliveryStartDate, loadGroup?.loads?.[legIndex]?.configMoveType, loadMoveType])

    const getLotId = (prev, name, type) => {
        if (prev?.type === groupLegTypes.ACTIVITY) {
            return prev?.lotId;
        } else {
            return prev?.[`${type}NewLot`] ? prev?.[`${name}NewLotId`] : prev?.[`${name}LocationId`];
        }
    }

    const isPULocationAsPrev = () => {
        if (legIndex == 0) return true;
        let previousLeg = loadGroup?.loads?.[legIndex - 1];
        let prevLotId = getLotId(previousLeg, "delivery", 'destination');
        let currentLotId = getLotId(loadGroup?.loads?.[legIndex], "pickup", 'origin')
        return prevLotId === currentLotId;
    }

    const isDLocationAsNext = () => {
        if (legIndex == (loadGroup?.loads?.length - 1)) return true;
        let nextLeg = loadGroup?.loads?.[legIndex + 1];
        let nextLotId = getLotId(nextLeg, "pickup");
        let currentLotId = getLotId(loadGroup?.loads?.[legIndex], "delivery")
        return nextLotId === currentLotId;
    }

    let form = (
        <Grid direction='row' container >
            <Grid item xs={12}>
                <LocationField
                    clientId={isClient ? loggedInUserClientId : loadGroup?.clientId}
                    name="pickup"
                    title="Origin"
                    onChange={onChange}
                    onChangeLocation={onChangeLocation}
                    newLoad={loadGroup?.loads?.[legIndex]}
                    onChangeValue={onChangeValue}
                    errors={loadsErrors}
                    loadGroup={loadGroup?.loads?.[legIndex]}
                    newLot={loadGroup?.loads?.[legIndex]?.originNewLot}
                    setNewLot={(val) => {
                        if (!val) {
                            clearErrorForFields(['pickupPocEmail', 'pickupPhone']);
                        }
                        onChangeValue({ "originNewLot": val })
                    }}
                    showMandatoryError={showMandatoryError}
                    allowNewLots={clientConfigs?.config?.transportConfig?.allowNewLots}
                />
                {!isPULocationAsPrev() &&
                    <Alert severity='warning' >
                        The selected pickup lot is not the same of the previous leg's delivery lot, this may indicate a problem with the setup of the load
                    </Alert>
                }
                <LocationField
                    clientId={isClient ? loggedInUserClientId : loadGroup?.clientId}
                    name="delivery"
                    title="Destination"
                    onChange={onChange}
                    onChangeLocation={onChangeLocation}
                    newLoad={loadGroup?.loads?.[legIndex]}
                    onChangeValue={onChangeValue}
                    errors={loadsErrors}
                    loadGroup={loadGroup?.loads?.[legIndex]}
                    newLot={loadGroup?.loads?.[legIndex]?.destinationNewLot}
                    setNewLot={(val) => {
                        if (!val) {
                            clearErrorForFields(['deliveryPocEmail', 'deliveryPhone']);
                        }
                        onChangeValue({ "destinationNewLot": val })
                    }}
                    showMandatoryError={showMandatoryError}
                    allowNewLots={clientConfigs?.config?.transportConfig?.allowNewLots}
                />
                {!isDLocationAsNext() &&
                    <Alert severity='warning' >
                        The selected delivery lot is not the same of the next leg's pickup lot, this may indicate a problem with the setup of the load
                    </Alert>
                }

                <LocationDistance onChange={onChange} newLoad={loadGroup?.loads?.[legIndex]} originNewLot={loadGroup?.loads?.[legIndex]?.originNewLot} destinationNewLot={loadGroup?.loads?.[legIndex]?.destinationNewLot} showMandatoryError={showMandatoryError} />

                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="moveType"
                                label="Move Type"
                                value={loadGroup?.loads?.[legIndex]?.configMoveType}
                                elementType="dropdown"
                                disabled={!clientMoveTypes?.length}
                                onChange={val => onMoveTypeChange(val)}
                                values={clientMoveTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.configMoveType)}
                                touched
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="trailerType"
                                label="Trailer Type"
                                value={loadGroup?.loads?.[legIndex]?.trailerType ?? 'TO_BE_DRIVEN'}
                                elementType="dropdown"
                                onChange={val => onChange("trailerType", val)}
                                values={trailerTypes}
                                showEmpty
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.trailerType)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.dateInput}>
                            <DatePicker
                                title="Earliest Pick Up Date"
                                value={(loadGroup?.loads?.[legIndex]?.pickupStartDate) ? moment(loadGroup?.loads?.[legIndex]?.pickupStartDate).format("YYYY-MM-DD") : null}
                                onSelect={val => {
                                    onChangePickupDate(val)
                                }}
                                minDate={moment(getPickupMinDate()).format("YYYY-MM-DD")}
                                maxDate={getPickupMaxDate()}
                                required
                                disabled={pickupDateDisabled}
                                valid={!loadsErrors['pickupStartDate'] && !(showMandatoryError && !loadGroup?.loads?.[legIndex]?.pickupStartDate)}
                                touched
                            />
                            <Tooltip title={puDateMessage} arrow className={classes.tooltipIcon}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                    </Grid>
                    {
                        warnings['pickupStartDate'] && showWarning &&
                        <Grid item className={classes.warningAlert}>
                            <><Alert severity="info">{warnings['pickupStartDate']}</Alert><br /></>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <div className={classes.dateInput}>
                            <DatePicker
                                title="Latest Delivery Date"
                                value={(loadGroup?.loads?.[legIndex]?.deliveryStartDate) ? moment(loadGroup?.loads?.[legIndex]?.deliveryStartDate).format("YYYY-MM-DD") : null}
                                onSelect={val => onChange("deliveryStartDate", val)}
                                minDate={moment(getDeliveryMinDate()).format("YYYY-MM-DD")}
                                maxDate={getDeliveryMaxDate()}
                                required
                                disabled={deliveryDateDisabled}
                                valid={!loadsErrors['deliveryStartDate'] && !(showMandatoryError && !loadGroup?.loads?.[legIndex]?.deliveryStartDate)}
                                touched
                            />
                            <Tooltip title={dDateMessage} arrow className={classes.tooltipIcon}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                    </Grid>
                    {
                        warnings['deliveryStartDate'] && showWarning &&
                        <Grid item className={classes.warningAlert}>
                            <><Alert severity="info">{warnings['deliveryStartDate']}</Alert><br /></>
                        </Grid>
                    }
                </Grid>

                <Grid container>
                    {clientConfigs?.config?.transportConfig?.needByWeek &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="neededByWeek"
                                    label="Need by week"
                                    value={loadGroup?.loads?.[legIndex]?.neededByWeek ?? ''}
                                    onChange={val => onChange("neededByWeek", +val)}
                                    elementType="input"
                                    required
                                    numberOnly
                                    valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.neededByWeek)}
                                    touched
                                    minValue={0}
                                />
                            </div>
                        </Grid>
                    }
                    {clientConfigs?.config?.transportConfig?.handOffWeek &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="handoffWeek"
                                    label="Handoff week"
                                    value={loadGroup?.loads?.[legIndex]?.handoffWeek ?? ''}
                                    onChange={val => onChange("handoffWeek", +val)}
                                    elementType="input"
                                    required
                                    numberOnly
                                    valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.handoffWeek)}
                                    touched
                                    minValue={0}
                                />
                            </div>
                        </Grid>
                    }
                </Grid>

                <div className={classes.input}>
                    <CustomInput
                        id="comments"
                        label="Comments"
                        value={loadGroup?.loads?.[legIndex]?.comments}
                        elementType="textarea"
                        onChange={val => onChange("comments", val)}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="shipperLoadId"
                        label="Shipper Load ID"
                        value={loadGroup?.loads?.[legIndex]?.shipperLoadId ?? ''}
                        elementType="input"
                        onChange={val => onChange("shipperLoadId", val)}
                    />
                </div>
                {!isClient && <div className={classes.input}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={loadGroup?.loads?.[legIndex]?.ignoreForSla}
                            onChange={(e, value) => onChange("ignoreForSla", value)}
                        />}
                        label="Ignore for SLA" />
                </div>}
            </Grid>
        </Grid >
    );


    return (
        <>
            {form}
        </>
    );
};

export default GroupLoadForm;
